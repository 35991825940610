import { Injectable } from '@angular/core';
import {
  AuthOutput,
  AuthTokensOutput,
  DeleteAccountGQL,
  LoginGQL,
  ProfileGQL,
  RefreshTokensGQL,
  RefreshTokensMutation,
  SignInInput,
  SignUpGQL,
  SignUpInput,
  UserOutputFragment,
} from '../../../generated/iota-core.api';
import { StorageService } from '../_shared/storage.service';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FetchResult } from '@apollo/client';
import { MenuController } from '@ionic/angular';
import { AuthTokens } from './dto/auth.interface';
import { RoutingService } from '../_shared/routing.service';
import { AlertService } from '../_shared/alert.service';
import { environment } from '../../environments/environment';

enum StorageKey {
  ACCESS_TOKEN_KEY = 'ACCESS_TOKEN',
  REFRESH_TOKEN_KEY = 'REFRESH_TOKEN',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authTokens: AuthTokens = { accessToken: '', refreshToken: '' };
  public $isAuthenticated = new BehaviorSubject<boolean>(false);
  public user: UserOutputFragment;

  constructor(
    private signUpGQL: SignUpGQL,
    private storage: StorageService,
    private loginGQL: LoginGQL,
    private router: Router,
    private profileGQL: ProfileGQL,
    private refreshTokensGQL: RefreshTokensGQL,
    private deleteAccountGQL: DeleteAccountGQL,
    private menuCtrl: MenuController,
    private routingService: RoutingService,
    private alertService: AlertService,
  ) {
    this.resetState();
  }

  public get isAuthenticated(): boolean {
    return this.$isAuthenticated.value;
  }

  public set isAuthenticated(value: boolean) {
    this.$isAuthenticated.next(value);
  }

  public resetState(): void {
    this.isAuthenticated = false;
    this.authTokens = { accessToken: '', refreshToken: '' };
    this.user = undefined;
  }

  public async signUp(input: SignUpInput): Promise<AuthOutput> {
    const auth = await this.signUpGQL
      .mutate({ input })
      .toPromise()
      .then((res) => res.data.signUp);

    console.info({ auth });
    await this.saveTokensAndInitUser(auth);
    return auth;
  }

  public async login(input: SignInInput): Promise<AuthOutput> {
    const auth = await this.loginGQL
      .mutate({ input })
      .toPromise()
      .then((res) => res.data.signIn);

    await this.saveTokensAndInitUser(auth);
    return auth;
  }

  public async signOut(): Promise<void> {
    await this.removeAuthTokens();
    this.isAuthenticated = false;
    await this.menuCtrl.enable(false);
  }

  public async signOutAndRedirect(): Promise<void> {
    await this.signOut();
    await this.routingService.goToLanding();
  }

  private async removeAuthTokens(): Promise<void> {
    this.authTokens = { accessToken: '', refreshToken: '' };
    await this.storage.remove(StorageKey.ACCESS_TOKEN_KEY);
    await this.storage.remove(StorageKey.REFRESH_TOKEN_KEY);
  }

  private async saveTokensAndInitUser(auth: AuthOutput): Promise<void> {
    const { user } = auth;
    const { accessToken, refreshToken } = auth.tokens;
    await this.saveAuthTokens({
      accessToken,
      refreshToken,
    });
    await this.initUser(user);
  }

  private async saveAuthTokens(authTokens: AuthTokensOutput): Promise<void> {
    const { accessToken, refreshToken } = authTokens;

    this.authTokens = { accessToken, refreshToken };
    await this.storage.set(StorageKey.ACCESS_TOKEN_KEY, accessToken);
    await this.storage.set(StorageKey.REFRESH_TOKEN_KEY, refreshToken);
  }

  private async initUser(user: UserOutputFragment): Promise<void> {
    console.info({ user });
    this.user = user;
    this.isAuthenticated = true;
    await this.menuCtrl.enable(true);
  }

  public async loadAuthTokens() {
    const accessToken = await this.storage.get(StorageKey.ACCESS_TOKEN_KEY);
    const refreshToken = await this.storage.get(StorageKey.REFRESH_TOKEN_KEY);

    if (accessToken && refreshToken) {
      this.authTokens = { accessToken, refreshToken };
      try {
        const user = await this.getProfile();
        await this.initUser(user);
      } catch (err) {
        console.warn({ err });
        this.isAuthenticated = false;
      }
    } else {
      this.isAuthenticated = false;
    }
  }

  public async getProfile(): Promise<UserOutputFragment> {
    return this.profileGQL
      .fetch(null, {
        fetchPolicy: 'network-only',
      })
      .toPromise()
      .then((r) => r.data.profile);
  }

  public refreshTokens(): Observable<AuthTokensOutput> {
    return this.refreshTokensGQL
      .mutate({ input: { refreshToken: this.authTokens.refreshToken } })
      .pipe(
        map(
          (res: FetchResult<RefreshTokensMutation>) =>
            res.data.refreshAuthTokens,
        ),
        tap(async (authTokens) => {
          await this.saveAuthTokens(authTokens);
        }),
      );
  }

  public async presentDeleteAccountConfirmation(): Promise<void> {
    const confirmation = await this.alertService.askConfirmation({
      title: 'Delete account',
      message:
        'All your data will be deleted. Are you sure you want to continue?',
    });
    if (confirmation) {
      const loader = await this.alertService.presentLoader();
      await this.deleteUserAccount();
      await loader.dismiss();
      await this.alertService.presentAlert('Your account has been deleted');
      await this.signOutAndRedirect();
    }
  }

  private async deleteUserAccount(): Promise<void> {
    if (environment.useDummies) {
      return Promise.resolve();
    }
    await this.deleteAccountGQL.mutate().toPromise();
  }
}
