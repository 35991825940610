import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { StorageService } from './_shared/storage.service';
import { Apollo } from 'apollo-angular';
import { AuthService } from './auth/auth.service';
import { RoleService } from './auth/role.service';
import { environment } from '../environments/environment';
import { RoutingService } from './_shared/routing.service';
import { TranslateConfigService } from './translate-config.service';

const packageJson = require('../../package.json');

interface AppPage {
  title: string;
  url: string;
  icon: string;
  activeIcon: string;
  restriction?: boolean;
}

interface MenuAppPage {
  adminMenu: AppPage[];
  customerMenu: AppPage[];
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages: AppPage[] = [];
  public currentPage: AppPage;
  public language: string;
  public version = packageJson.version;
  public subVersion = environment.production ? '' : packageJson.subversion;
  private userIsAuthenticated = false;

  constructor(
    private storage: StorageService,
    public platform: Platform,
    private apollo: Apollo,
    private menuCtrl: MenuController,
    private roleService: RoleService,
    public authService: AuthService,
    private routingService: RoutingService,
    private translateConfigService: TranslateConfigService,
  ) {
    this.initializeApp().then();
  }

  private async initializeApp(): Promise<void> {
    await this.storage.init();
    await this.platform.ready();
    this.translateConfigService.getDefaultLanguage().then();
    this.language = await this.translateConfigService.getCurrentLang();
    this.authService.$isAuthenticated.subscribe(async (isAuthenticated) => {
      const userWasAuthenticated = this.userIsAuthenticated;
      this.userIsAuthenticated = isAuthenticated;

      if (userWasAuthenticated && !this.userIsAuthenticated) {
        this.userIsAuthenticated = isAuthenticated;
        // this.resetAppState();
      } else if (!userWasAuthenticated && this.userIsAuthenticated) {
        this.initMenu();
      }
    });
    await this.menuCtrl.enable(false);
  }

  private resetAppState(): void {
    this.authService.resetState();
    this.storage.resetState();
    this.resetAppQueryCache();
  }

  private resetAppQueryCache(): void {
    // this.apollo.client.clearStore().then();
  }

  public openPage(page: AppPage): void {
    this.currentPage = page;
  }

  private initMenu(): void {
    const allMenuPages = this.createMenuPages();
    if (this.roleService.isTech || this.roleService.isAdmin) {
      this.appPages = allMenuPages.adminMenu;
    } else if (this.roleService.isCustomer) {
      this.appPages = allMenuPages.customerMenu;
    }
    this.currentPage = this.appPages[0];
  }

  private createMenuPages(): MenuAppPage {
    const { isTech, isAdmin, isCustomer } = this.roleService;

    return {
      adminMenu: [
        {
          title: 'Home',
          url: 'home',
          icon: 'assets/imgs/menu1.svg',
          activeIcon: 'assets/imgs/menu1a.svg',
          restriction: isAdmin || isTech,
        },
      ],
      customerMenu: [
        {
          title: 'My Devices',
          url: 'dashboard',
          icon: 'assets/imgs/menu1.svg',
          activeIcon: 'assets/imgs/menu1a.svg',
          restriction: isCustomer,
        },
        {
          title: 'Add a New Device',
          url: 'define-holder',
          icon: 'assets/imgs/menu3.svg',
          activeIcon: 'assets/imgs/menu3a.svg',
          restriction: isCustomer,
        },
        {
          title: 'Alerts',
          url: 'alerts',
          icon: 'assets/imgs/menu2.svg',
          activeIcon: 'assets/imgs/menu2a.svg',
          restriction: isCustomer,
        },
        {
          title: 'Account Settings',
          url: 'my-account',
          icon: 'assets/imgs/menu4.svg',
          activeIcon: 'assets/imgs/menu4a.svg',
          restriction: isCustomer,
        },
      ],
    };
  }

  public async goToMyAccount(): Promise<void> {
    await this.routingService.goToMyAccount();
    await this.menuCtrl.close();
  }
}
