import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private ionicStorage: Storage) {}

  public resetState(): void {
    this.storage.clear().then(() => {
      this.storage = null;
      this.init().then();
    });
  }

  public async init() {
    this.storage = await this.ionicStorage.create();
  }

  public set(key: string, value: any) {
    this.storage.set(key, value).then();
  }

  public get(key: string) {
    return this.storage.get(key);
  }

  public remove(key: string) {
    return this.storage.remove(key);
  }
}
