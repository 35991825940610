import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RoutingService } from '../../_shared/routing.service';
import { RoleService } from '../role.service';

@Injectable({
  providedIn: 'root',
})
export class IsInternalGuard implements CanActivate {
  constructor(
    private roleService: RoleService,
    private routingService: RoutingService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.roleService.isInternal) {
      await this.routingService.goToLanding();
      return false;
    }

    return true;
  }
}
