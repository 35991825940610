import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserRole } from '../../../generated/iota-core.api';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private authService: AuthService) {}

  public get userRole(): UserRole {
    return this.authService.user?.role;
  }

  public get isAdmin(): boolean {
    return this.userRole === UserRole.Admin;
  }

  public get isTech(): boolean {
    return this.userRole === UserRole.Tech;
  }

  public get isCustomer(): boolean {
    return this.userRole === UserRole.Customer;
  }

  public get isInternal(): boolean {
    return this.isAdmin || this.isTech;
  }
}
