export enum AppErrorCode {
  UNKNOWN = 'unknown',
  INVALID_FORM = 'invalid_form',
  INVALID_CREDENTIALS = 'auth/invalid_credentials',
  INVALID_EMAIL_ADDRESS_FORMAT = 'auth/invalid_email_address_format',
  INVALID_PASSWORD_FORMAT = 'auth/invalid_password_format',
  UNKNOWN_EMAIL_ADDRESS = 'auth/unknown_email_address',
  NAME_REQUIRED = 'calibration/name_required',
  QRCODE_UNRECOGNIZED = 'qrcode/unrecognized',
  INVALID_PICTURE_FORMAT = 'picture/invalid_format',
}

export type AppError = { code: AppErrorCode; message: string; title?: string };

export const APP_ERRORS: AppError[] = [
  {
    code: AppErrorCode.UNKNOWN,
    message:
      "An unknown error has occurred. We're so sorry about that. Please try again.",
  },
  {
    code: AppErrorCode.INVALID_FORM,
    message:
      'One of your field is either invalid or missing. Please check and try again',
  },
  {
    code: AppErrorCode.INVALID_CREDENTIALS,
    message: 'The email or password is incorrect. Please try again.',
  },
  {
    code: AppErrorCode.INVALID_EMAIL_ADDRESS_FORMAT,
    message:
      'The email format is incorrect. Please register with a valid mail address.',
  },
  {
    code: AppErrorCode.INVALID_PASSWORD_FORMAT,
    message:
      'The password format is incorrect. It should contains at least 6 characters (with 1 uppercase letter and 1 number)',
  },
  {
    code: AppErrorCode.NAME_REQUIRED,
    message: 'The name is required. Please enter a name.',
  },
  {
    code: AppErrorCode.QRCODE_UNRECOGNIZED,
    message: 'The QR code is not recognized. Please try again.',
  },
  {
    code: AppErrorCode.INVALID_PICTURE_FORMAT,
    message: 'The picture format is not supported. Please try again.',
  },
];
