import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './_shared/storage.service';

enum StorageKey {
  LANG = 'LANG',
}

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  public currentLang: string;
  constructor(
    private translate: TranslateService,
    private storage: StorageService,
  ) {
    this.getDefaultLanguage().then();
  }

  public async getDefaultLanguage(): Promise<string> {
    await this.storage.init();
    this.currentLang = await this.storage.get(StorageKey.LANG);
    if (this.currentLang) {
      this.translate.setDefaultLang(this.currentLang);
    } else {
      this.currentLang = this.translate.getBrowserLang();
      await this.storage.set(StorageKey.LANG, this.currentLang);
      this.translate.setDefaultLang(this.currentLang);
    }
    return this.currentLang;
  }

  public async setLanguage(setLang: string): Promise<void> {
    this.translate.use(setLang);
    await this.storage.set(StorageKey.LANG, setLang);
  }

  public async getCurrentLang(): Promise<string> {
    return this.storage.get(StorageKey.LANG);
  }

  public async get(key: string | string[]): Promise<string> {
    return this.translate.get(key).toPromise();
  }
}
