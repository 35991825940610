import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HolderFragment, UserRole } from '../../../generated/iota-core.api';
import { BluetoothMode } from '../bluetooth/ble.dto';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private router: Router) {}

  public getNavigationState(): any {
    return this.router.getCurrentNavigation()?.extras.state;
  }

  public async goToRootPage(role?: UserRole): Promise<void> {
    if (!role) {
      await this.router.navigateByUrl('/landing');
      return;
    }
    UserRole.Customer === role
      ? await this.router.navigateByUrl('/dashboard')
      : await this.router.navigateByUrl('/internal-dashboard');
  }

  public async goToLanding(): Promise<void> {
    await this.router.navigateByUrl(`/landing`, { replaceUrl: true });
  }

  public async goToLogin(): Promise<void> {
    await this.router.navigateByUrl(`/login`, { replaceUrl: true });
  }

  public async goToSignUp(): Promise<void> {
    await this.router.navigateByUrl(`/signup`, { replaceUrl: true });
  }

  public async goToRegisterNewDevice(): Promise<void> {
    await this.router.navigateByUrl(`/define-holder`, {
      replaceUrl: true,
    });
  }

  public async goToAddDevice(args: { holderId: string }): Promise<void> {
    await this.router.navigateByUrl(`/add-device`, {
      replaceUrl: true,
      state: { holderId: args.holderId },
    });
  }

  public async goToMyAccount(): Promise<void> {
    await this.router.navigateByUrl(`/my-account`, { replaceUrl: true });
  }

  public async goToBluetoothPrerequisite(): Promise<void> {
    await this.router.navigateByUrl(`/bluetooth/prerequisite`);
  }

  public async goToBluetoothSearchPairDevice(
    bluetoothMode: BluetoothMode,
  ): Promise<void> {
    const navExtras: NavigationExtras = { state: { bluetoothMode } };
    await this.router.navigateByUrl(`/bluetooth/pair-device`, navExtras);
  }

  public async goToHolderStatistic(holder: HolderFragment): Promise<void> {
    const navExtras: NavigationExtras = { state: { holder } };
    await this.router.navigateByUrl(`/dashboard/statistic`, navExtras);
  }
}
