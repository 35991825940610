import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshingTokens = false;
  private _authService: AuthService;
  private requestPile: { req: HttpRequest<any>; next: HttpHandler }[] = [];

  constructor(private injector: Injector) {}

  private get authService() {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request)).pipe(
      tap((res: HttpResponse<any>) => {
        this.throwIfGraphqlError(res);
      }),
      catchError((err) => {
        console.warn({ err });

        if (err.message === 'Unauthorized') {
          if (this.isRefreshingTokens) {
            this.pileRequest(request, next);
          } else {
            return this.refreshTokensAndRetryRequest(next, request).pipe(
              tap(() => {
                this.unpileRequests();
              }),
            );
          }
        }

        return throwError(err);
      }),
    );
  }

  private throwIfGraphqlError = (res: HttpResponse<any>) => {
    if (res.body?.errors && res.body?.errors[0]?.message === 'Unauthorized') {
      throw new Error('Unauthorized');
    }
  };

  private addToken(req: HttpRequest<any>) {
    const { accessToken } = this.authService.authTokens;
    if (!accessToken) {
      return req;
    }

    return req.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
  }

  private pileRequest(req: HttpRequest<any>, next: HttpHandler) {
    this.requestPile.push({ req, next });
  }

  private unpileRequests() {
    for (const request of this.requestPile) {
      const { req, next } = request;
      next.handle(this.addToken(req));
    }
  }

  private refreshTokensAndRetryRequest(
    next: HttpHandler,
    req: HttpRequest<any>,
  ) {
    this.isRefreshingTokens = true;

    return this.authService.refreshTokens().pipe(
      switchMap(() => {
        return next.handle(this.addToken(req)).pipe(
          tap(() => {
            this.isRefreshingTokens = false;
          }),
          catchError((afterTokenRefreshError) => {
            console.warn({ afterTokenRefreshError });
            this.isRefreshingTokens = false;
            this.authService.signOutAndRedirect().then();
            return throwError(afterTokenRefreshError);
          }),
        );
      }),
      catchError((refreshingTokenError) => {
        console.warn({ refreshingTokenError });
        this.isRefreshingTokens = false;
        this.authService.signOutAndRedirect().then();
        return throwError(refreshingTokenError);
      }),
    );
  }
}
