import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { APP_ERRORS, AppError, AppErrorCode } from '../app.error';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertCtrl: AlertController,
    private loaderCtrl: LoadingController,
    private snackBar: MatSnackBar,
    private toastCtrl: ToastController,
  ) {}

  public async presentAlert(
    message: string,
    title?: string,
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header: title,
      message,
      buttons: [{ id: 'confirm-button', role: 'cancel', text: 'OK' }],
      mode: 'md',
    });
    await alert.present();
    return alert.onDidDismiss().then((res) => res.data);
  }

  public async presentError(
    errCode: AppErrorCode | string = AppErrorCode.UNKNOWN,
  ): Promise<HTMLIonAlertElement> {
    let appError: AppError;
    let message: string;
    let title = 'Oups !';

    const clientError = APP_ERRORS.find((e) => e.code === errCode);
    if (clientError) {
      appError = clientError;
      message = appError.message;
      title = appError.title || title;
    } else {
      message = errCode;
    }

    return this.presentAlert(message, title);
  }

  public async presentLoader(message?: string): Promise<HTMLIonLoadingElement> {
    const loader = await this.loaderCtrl.create({
      spinner: 'crescent',
      mode: 'ios',
      message,
    });
    await loader.present();
    return loader;
  }

  public presentSnackbar(args: { text: string }): void {
    const { text } = args;
    this.snackBar.open(text, null, {
      duration: 2000,
      panelClass: 'confirm-snackbar',
    });
  }

  public async askConfirmation(args: {
    message: string;
    title?: string;
  }): Promise<boolean> {
    const { message, title = null } = args;

    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message,
        mode: 'md',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            id: 'cancel-button',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: 'Yes',
            id: 'confirm-button',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  async dismissLoader() {
    await this.loaderCtrl.dismiss();
  }

  public async presentToast(args: {
    message: string;
    position?: 'top' | 'middle' | 'bottom';
    color?:
      | 'success'
      | 'warning'
      | 'danger'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'light'
      | 'medium'
      | 'dark';
    icon?: string;
    duration?: number;
  }): Promise<void> {
    const { message, position, color, icon, duration } = args;

    const toast = await this.toastCtrl.create({
      message,
      duration,
      position,
      icon,
      color,
    });
    await toast.present();
  }
}
