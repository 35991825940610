import { NgModule } from '@angular/core';
import { Apollo, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';

const { host } = environment.server;
export const graphqlUrl = `${host}/graphql`; // <-- add the URL of the GraphQL server here
export const createApollo = (httpLink: HttpLink): ApolloClientOptions<any> => ({
  link: httpLink.create({ uri: graphqlUrl }),
  cache: new InMemoryCache(),
});

@NgModule({
  providers: [
    Apollo,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          core: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: `${environment.server.host}/graphql`,
            }),
          },
          custom: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: `${environment.serverSpecific.host}/graphql`,
            }),
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
