import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { RoutingService } from '../../_shared/routing.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.authService.isAuthenticated) {
      await this.authService.loadAuthTokens();
    }

    if (!this.authService.isAuthenticated) {
      await this.routingService.goToLanding();
      return false;
    }

    return true;
  }
}
