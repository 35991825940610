// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useDummies: true,
  useMocKDevice: true,
  server: {
    host: 'https://core-3ra32zk4mq-od.a.run.app', // Remote Dev server, don't forget to run script in .graphqlconfig (root folder) if you use it.
    // host: 'http://localhost:3000',
  },
  serverSpecific: {
    host: "https://custom-3ra32zk4mq-od.a.run.app", // Remote specific Dev server
    // host: 'http://localhost:3000',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
