import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './auth/guard/no-auth.guard';
import { AuthGuard } from './auth/guard/auth.guard';
import { IsInternalGuard } from './auth/guard/is-internal.guard';

const routes: Routes = [
  {
    path: 'internal-dashboard',
    loadChildren: () =>
      import('./internal-dashboard/internal-dashboard.module').then(
        (m) => m.InternalDashboardPageModule,
      ),
    canActivate: [AuthGuard, IsInternalGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/statistic',
    loadChildren: () =>
      import('./dashboard/statistic/statistic.module').then(
        (m) => m.StatisticPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/signin/signin.module').then((m) => m.SigninPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/signup.module').then((m) => m.SignupPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./my-account/my-account.module').then(
        (m) => m.MyAccountPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-device',
    loadChildren: () =>
      import('./dashboard/add-device/add-device.module').then(
        (m) => m.AddDevicePageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'define-holder',
    loadChildren: () =>
      import('./dashboard/define-holder/define-holder.module').then(
        (m) => m.DefineHolderPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'qrcode-scanner',
    loadChildren: () =>
      import('./dashboard/qrcode-scanner/qrcode-scanner.module').then(
        (m) => m.QRCodeScannerPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'bluetooth/pair-device',
    loadChildren: () =>
      import(
        './bluetooth/search-display-pair-device/search-display-pair-device.module'
      ).then((m) => m.SearchDisplayPairDevicePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bluetooth/prerequisite',
    loadChildren: () =>
      import(
        './bluetooth/bluetooth-prerequisite/bluetooth-prerequisite.module'
      ).then((m) => m.BluetoothPrerequisitePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
