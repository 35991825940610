import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Represents NULL values */
  Void: any;
};

export type AlertOutput = {
  __typename?: 'AlertOutput';
  createdAt: Scalars['DateTime'];
  fieldName?: Maybe<Scalars['String']>;
  holder: HolderOutput;
  id: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  sensorValue?: Maybe<Scalars['Float']>;
  threshold: Scalars['Float'];
  type: AlertType;
};

export enum AlertSortableField {
  CreatedAt = 'createdAt'
}

export type AlertTriggerOutput = {
  __typename?: 'AlertTriggerOutput';
  id: Scalars['String'];
};

export enum AlertType {
  Battery = 'BATTERY',
  Data = 'DATA',
  Inactivity = 'INACTIVITY'
}

export type ApiVersionOutput = {
  __typename?: 'ApiVersionOutput';
  current: Scalars['String'];
  minimum: Scalars['String'];
};

export type AssignCalibrationToDeviceInput = {
  calibrationId: Scalars['String'];
  deviceExternalId: Scalars['String'];
};

export type AssignDeviceToHolderInput = {
  deviceExternalId: Scalars['String'];
  holderId: Scalars['String'];
};

export type AuthOutput = {
  __typename?: 'AuthOutput';
  tokens: AuthTokensOutput;
  user: UserOutput;
};

export type AuthTokensOutput = {
  __typename?: 'AuthTokensOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type CalibrationOutput = {
  __typename?: 'CalibrationOutput';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentVersion: CalibrationVersionOutput;
  disabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum CalibrationSearchableField {
  Comment = 'comment',
  Id = 'id',
  Name = 'name'
}

export enum CalibrationSortableField {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type CalibrationUpdateDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  currentVersionId?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CalibrationVersionOutput = {
  __typename?: 'CalibrationVersionOutput';
  comment?: Maybe<Scalars['String']>;
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Float'];
};

export type ClaimDeviceInput = {
  deviceExternalId: Scalars['String'];
};

export type CloseSupportTicketInput = {
  id: Scalars['String'];
};

export type CompanySettingOutput = {
  __typename?: 'CompanySettingOutput';
  faviconUrl: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  primaryColor: Scalars['String'];
};

export type CreateCalibrationInput = {
  comment?: InputMaybe<Scalars['String']>;
  firstVersion: CreateFirstCalibrationVersionInput;
  name: Scalars['String'];
};

export type CreateCalibrationVersionInput = {
  calibrationId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  content: Scalars['JSON'];
};

export type CreateDataAlertTriggerInput = {
  holderId: Scalars['String'];
};

export type CreateDeviceEventInput = {
  content?: InputMaybe<Scalars['String']>;
  creatorId?: InputMaybe<Scalars['String']>;
  deviceExternalId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['JSON']>;
  type: DeviceEventType;
};

export type CreateDeviceInput = {
  comment?: InputMaybe<Scalars['String']>;
  customId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  status?: InputMaybe<DeviceStatus>;
};

export type CreateDeviceTemplateInput = {
  decoderId: Scalars['String'];
  measurements: Array<CreateDeviceTemplateMeasurementInput>;
  name: Scalars['String'];
  networkOrigin: NetworkOrigin;
};

export type CreateDeviceTemplateMeasurementInput = {
  decoderKey: Scalars['String'];
  displayed: Scalars['Boolean'];
  name: Scalars['String'];
  unit: Scalars['String'];
};

export type CreateFirstCalibrationVersionInput = {
  comment?: InputMaybe<Scalars['String']>;
  content: Scalars['JSON'];
};

export type CreateHolderInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  holderTemplateId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateHolderTemplateInput = {
  items: Array<CreateHolderTemplateItemInput>;
  name: Scalars['String'];
};

export type CreateHolderTemplateItemInput = {
  name: Scalars['String'];
  options?: InputMaybe<Array<CreateHolderTemplateItemSelectListOptionInput>>;
  rank: Scalars['Int'];
  required: Scalars['Boolean'];
  type: HolderTemplateItemType;
};

export type CreateHolderTemplateItemSelectListOptionInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  requiredSelectListOptionId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateInternalNoteInput = {
  content: Scalars['String'];
  supportTicketId: Scalars['String'];
};

export type CreateSelectListOptionInput = {
  holderTemplateItemId: Scalars['String'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  requiredSelectListOptionId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateSupportTicketInput = {
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  priority: SupportTicketPriority;
  techId?: InputMaybe<Scalars['String']>;
};

export type DateFilterInput = {
  gte?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type DecoderOutput = {
  __typename?: 'DecoderOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DeleteDeviceInput = {
  id: Scalars['String'];
};

export type DeleteUserInput = {
  id: Scalars['String'];
};

export type DeviceEventOutput = {
  __typename?: 'DeviceEventOutput';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creator: UserOutput;
  device: DeviceOutput;
  deviceId: Scalars['String'];
  id: Scalars['String'];
  params?: Maybe<Scalars['JSON']>;
  type: DeviceEventType;
  updatedAt: Scalars['DateTime'];
};

export enum DeviceEventSearchableField {
  Content = 'content',
  Id = 'id'
}

export enum DeviceEventSortableField {
  CreatedAt = 'createdAt'
}

export enum DeviceEventType {
  CalibrationChanged = 'CALIBRATION_CHANGED',
  Connected = 'CONNECTED',
  DataAlert = 'DATA_ALERT',
  Disconnected = 'DISCONNECTED',
  Inactive = 'INACTIVE',
  LowBattery = 'LOW_BATTERY',
  StatusChanged = 'STATUS_CHANGED',
  SupportTicketClosed = 'SUPPORT_TICKET_CLOSED',
  SupportTicketOpened = 'SUPPORT_TICKET_OPENED'
}

export type DeviceLogOutput = {
  __typename?: 'DeviceLogOutput';
  id: Scalars['String'];
};

export type DeviceOutput = {
  __typename?: 'DeviceOutput';
  battery?: Maybe<Scalars['Int']>;
  calibration?: Maybe<CalibrationOutput>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  customId?: Maybe<Scalars['String']>;
  deviceTemplate: DeviceTemplateOutput;
  directoryPath?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  geolocation?: Maybe<GeolocationOutput>;
  holder?: Maybe<HolderOutput>;
  holderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAllowedToLog: Scalars['Boolean'];
  isPaused?: Maybe<Scalars['Boolean']>;
  lastGeolocationAt?: Maybe<Scalars['DateTime']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  latestDeviceLog?: Maybe<DeviceLogOutput>;
  latestMesureTypesLogs?: Maybe<Array<DeviceLogOutput>>;
  manufacturer?: Maybe<Scalars['String']>;
  needPayloadDecoding: Scalars['Boolean'];
  openSupportTicketCount: Scalars['Int'];
  status: DeviceStatus;
  updatedAt: Scalars['DateTime'];
};

export type DeviceRegistrationSettingOutput = {
  __typename?: 'DeviceRegistrationSettingOutput';
  enabledToUsers: Scalars['Boolean'];
  explanationImageUrl: Scalars['String'];
  explanationText: Scalars['String'];
  title: Scalars['String'];
};

export enum DeviceSearchableField {
  Comment = 'comment',
  CustomId = 'customId',
  ExternalId = 'externalId',
  Id = 'id'
}

export type DeviceSettingOutput = {
  __typename?: 'DeviceSettingOutput';
  registration: DeviceRegistrationSettingOutput;
};

export enum DeviceSortableField {
  CreatedAt = 'createdAt',
  CustomId = 'customId',
  ExternalId = 'externalId',
  UpdatedAt = 'updatedAt'
}

export enum DeviceStatus {
  Affected = 'AFFECTED',
  Broken = 'BROKEN',
  InStock = 'IN_STOCK',
  NotAffected = 'NOT_AFFECTED',
  ReturnedInSupport = 'RETURNED_IN_SUPPORT',
  Stolen = 'STOLEN'
}

export type DeviceTemplateMeasurementOutput = {
  __typename?: 'DeviceTemplateMeasurementOutput';
  decoderKey: Scalars['String'];
  displayed: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
};

export type DeviceTemplateOutput = {
  __typename?: 'DeviceTemplateOutput';
  decoder: DecoderOutput;
  id: Scalars['String'];
  measurements: Array<DeviceTemplateMeasurementOutput>;
  name: Scalars['String'];
};

export type DeviceUpdateDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  customId?: InputMaybe<Scalars['String']>;
  directoryPath?: InputMaybe<Scalars['String']>;
  isAllowedToLog?: InputMaybe<Scalars['Boolean']>;
  isPaused?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  needPayloadDecoding?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<DeviceStatus>;
};

export type EmailingSettingOutput = {
  __typename?: 'EmailingSettingOutput';
  enabled: Scalars['Boolean'];
  templates: EmailingTemplateSettingOutput;
};

export type EmailingTemplateSettingOutput = {
  __typename?: 'EmailingTemplateSettingOutput';
  accountCreation: Scalars['String'];
  resetPassword: Scalars['String'];
  signup: Scalars['String'];
};

export type FilterAlertsInput = {
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<AlertType>;
};

export type FilterCalibrationVersionsInput = {
  calibrationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type FilterCalibrationsInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<SearchCalibrationsInput>;
};

export type FilterDeviceEventsInput = {
  content?: InputMaybe<Scalars['String']>;
  deviceCustomId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  holderId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<SearchDeviceEventsInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  types?: InputMaybe<Array<DeviceEventType>>;
};

export type FilterDevicesInput = {
  customerId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<SearchDevicesInput>;
  statuses?: InputMaybe<Array<DeviceStatus>>;
};

export type FilterHolderTemplateItemsInput = {
  type?: InputMaybe<HolderTemplateItemType>;
};

export type FilterHoldersInput = {
  customerId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<SearchHoldersInput>;
};

export type FilterSupportMessagesInput = {
  supportTicketId: Scalars['String'];
};

export type FilterSupportTicketsInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateFilterInput>;
  customerId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  priorities?: InputMaybe<Array<SupportTicketPriority>>;
  search?: InputMaybe<SearchSupportTicketsInput>;
  statuses?: InputMaybe<Array<SupportTicketStatus>>;
  techId?: InputMaybe<Scalars['String']>;
};

export type FilterUsersInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  customFields?: InputMaybe<Scalars['JSON']>;
  deviceId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  search?: InputMaybe<SearchUsersInput>;
};

export type FindManyUsersInput = {
  orderBy?: InputMaybe<Array<SortUsersInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterUsersInput>;
};

export type FindManyUsersOutput = {
  __typename?: 'FindManyUsersOutput';
  results: Array<UserOutput>;
  total: Scalars['Int'];
};

export type GeolocationOutput = {
  __typename?: 'GeolocationOutput';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GetDeviceEventInput = {
  orderBy?: InputMaybe<Array<SortDeviceEventsInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterDeviceEventsInput>;
};

export type GetDeviceEventOutput = {
  __typename?: 'GetDeviceEventOutput';
  results: Array<DeviceEventOutput>;
  total: Scalars['Int'];
};

export type GetHolderTemplateItemsInput = {
  holderTemplateId: Scalars['String'];
};

export type GetManyAlertsInput = {
  orderBy?: InputMaybe<Array<SortAlertsInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterAlertsInput>;
};

export type GetManyCalibrationVersionsInput = {
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterCalibrationVersionsInput>;
};

export type GetManyCalibrationVersionsOutput = {
  __typename?: 'GetManyCalibrationVersionsOutput';
  results: Array<CalibrationVersionOutput>;
  total: Scalars['Int'];
};

export type GetManyCalibrationsInput = {
  orderBy?: InputMaybe<Array<SortCalibrationsInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterCalibrationsInput>;
};

export type GetManyCalibrationsOutput = {
  __typename?: 'GetManyCalibrationsOutput';
  results: Array<CalibrationOutput>;
  total: Scalars['Int'];
};

export type GetManyDevicesInput = {
  orderBy?: InputMaybe<Array<SortDevicesInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterDevicesInput>;
};

export type GetManyHoldersInput = {
  orderBy?: InputMaybe<Array<SortHoldersInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterHoldersInput>;
};

export type GetManySupportMessagesInput = {
  where: FilterSupportMessagesInput;
};

export type GetManySupportTicketsInput = {
  orderBy?: InputMaybe<Array<SortSupportTicketsInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<FilterSupportTicketsInput>;
};

export type GetSupportTicketInput = {
  id: Scalars['String'];
};

export type GetSupportTicketOutput = {
  __typename?: 'GetSupportTicketOutput';
  results: Array<SupportTicketOutput>;
  total: Scalars['Int'];
};

export type GetUniqueCalibrationInput = {
  id: Scalars['String'];
};

export type GetUniqueDeviceInput = {
  externalId?: InputMaybe<Scalars['String']>;
  holderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type GetUniqueHolderInput = {
  id: Scalars['String'];
};

export type GetUniqueHolderTemplateInput = {
  id: Scalars['String'];
};

export type GetUniqueSelectListOptionInput = {
  id: Scalars['String'];
};

export type GetUserInput = {
  id: Scalars['String'];
};

export type HolderOutput = {
  __typename?: 'HolderOutput';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  device: DeviceOutput;
  directoryPath?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactivityTimeoutMinute?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pictureURL?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userRelations: Array<UserHolderOutput>;
};

export enum HolderSearchableField {
  Comment = 'comment',
  Id = 'id',
  Name = 'name'
}

export enum HolderSortableField {
  CreatedAt = 'createdAt',
  InactivityTimeoutMinute = 'inactivityTimeoutMinute',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type HolderTemplateItemOutput = {
  __typename?: 'HolderTemplateItemOutput';
  checked?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  hasChildItems: Scalars['Boolean'];
  holderTemplateId: Scalars['String'];
  id: Scalars['String'];
  maxValOrLength?: Maybe<Scalars['Float']>;
  minValOrLength?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentItemId?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  required: Scalars['Boolean'];
  requiredSelectListOptionId?: Maybe<Scalars['String']>;
  selectListOptions: Array<SelectListOptionOutput>;
  type: HolderTemplateItemType;
  updatedAt: Scalars['DateTime'];
};

export enum HolderTemplateItemType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Image = 'IMAGE',
  Number = 'NUMBER',
  Select = 'SELECT',
  String = 'STRING'
}

export type HolderTemplateOutput = {
  __typename?: 'HolderTemplateOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  items: Array<HolderTemplateItemOutput>;
  name: Scalars['String'];
  pictureURL?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type HolderTemplateOutputItemsArgs = {
  where?: InputMaybe<FilterHolderTemplateItemsInput>;
};

export type HolderUpdateDataInput = {
  comment?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  directoryPath?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pictureURL?: InputMaybe<Scalars['String']>;
};

export type HomePageSettingOutput = {
  __typename?: 'HomePageSettingOutput';
  imageUrl: Scalars['String'];
  welcomeText: Scalars['String'];
};

export type LightHolderOutput = {
  __typename?: 'LightHolderOutput';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  geolocation?: Maybe<GeolocationOutput>;
  id: Scalars['String'];
  inactivityTimeoutMinute?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pictureURL?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MarkAlertAsReadInput = {
  ids: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignCalibrationToDevice: DeviceOutput;
  assignDeviceToHolder: DeviceOutput;
  claimDevice: HolderOutput;
  closeSupportTicket: SupportTicketOutput;
  createCalibration: CalibrationOutput;
  createCalibrationVersion: CalibrationVersionOutput;
  createDataAlertTrigger: AlertTriggerOutput;
  createDevice: DeviceOutput;
  createDeviceEvent: DeviceEventOutput;
  createDeviceTemplate: DeviceTemplateOutput;
  createHolder: HolderOutput;
  createHolderTemplate: HolderTemplateOutput;
  createInternalNote: Array<SupportMessageOutput>;
  createSelectListOption: SelectListOptionOutput;
  createSupportTicket: SupportTicketOutput;
  deleteAccount?: Maybe<Scalars['Void']>;
  deleteDevice?: Maybe<Scalars['Void']>;
  deleteUser?: Maybe<Scalars['Void']>;
  markAlertsAsRead: Array<AlertOutput>;
  refreshAuthTokens: AuthTokensOutput;
  renameDeviceGroup?: Maybe<Scalars['Void']>;
  renameHolderGroup?: Maybe<Scalars['Void']>;
  requestPasswordReset?: Maybe<Scalars['Void']>;
  sendSupportMessage: Array<SupportMessageOutput>;
  signIn: AuthOutput;
  signOut?: Maybe<Scalars['Void']>;
  signUp: AuthOutput;
  signUpAnotherUser: UserOutput;
  updateBatteryAlertTrigger?: Maybe<Scalars['Void']>;
  updateCalibration: CalibrationOutput;
  updateDataAlertTrigger?: Maybe<Scalars['Void']>;
  updateDevice: DeviceOutput;
  updateDeviceTemplate: DeviceTemplateOutput;
  updateHolder: HolderOutput;
  updateHolderTemplate: HolderTemplateOutput;
  updateProfile: UserOutput;
  updateSelectListOption: SelectListOptionOutput;
  updateSettings: SettingOutput;
  updateSupportTicket: SupportTicketOutput;
  updateUser: UserOutput;
};


export type MutationAssignCalibrationToDeviceArgs = {
  input: AssignCalibrationToDeviceInput;
};


export type MutationAssignDeviceToHolderArgs = {
  input: AssignDeviceToHolderInput;
};


export type MutationClaimDeviceArgs = {
  input: ClaimDeviceInput;
};


export type MutationCloseSupportTicketArgs = {
  input: CloseSupportTicketInput;
};


export type MutationCreateCalibrationArgs = {
  input: CreateCalibrationInput;
};


export type MutationCreateCalibrationVersionArgs = {
  input: CreateCalibrationVersionInput;
};


export type MutationCreateDataAlertTriggerArgs = {
  createDataAlertTriggerInput: CreateDataAlertTriggerInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateDeviceEventArgs = {
  input: CreateDeviceEventInput;
};


export type MutationCreateDeviceTemplateArgs = {
  input: CreateDeviceTemplateInput;
};


export type MutationCreateHolderArgs = {
  input: CreateHolderInput;
};


export type MutationCreateHolderTemplateArgs = {
  input: CreateHolderTemplateInput;
};


export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
};


export type MutationCreateSelectListOptionArgs = {
  input: CreateSelectListOptionInput;
};


export type MutationCreateSupportTicketArgs = {
  input: CreateSupportTicketInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationMarkAlertsAsReadArgs = {
  input: MarkAlertAsReadInput;
};


export type MutationRefreshAuthTokensArgs = {
  input: RefreshAuthTokensInput;
};


export type MutationRenameDeviceGroupArgs = {
  input: RenameDeviceGroupInput;
};


export type MutationRenameHolderGroupArgs = {
  input: RenameHolderGroupInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationSendSupportMessageArgs = {
  input: SendSupportMessageInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpAnotherUserArgs = {
  input: SignUpAnotherUserInput;
};


export type MutationUpdateBatteryAlertTriggerArgs = {
  updateBatteryAlertTriggerInput: UpdateBatteryAlertTriggerInput;
};


export type MutationUpdateCalibrationArgs = {
  input: UpdateCalibrationInput;
};


export type MutationUpdateDataAlertTriggerArgs = {
  updateDataAlertTriggerInput: UpdateDataAlertTriggerInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceTemplateArgs = {
  input: UpdateDeviceTemplateInput;
};


export type MutationUpdateHolderArgs = {
  input: UpdateHolderInput;
};


export type MutationUpdateHolderTemplateArgs = {
  input: UpdateHolderTemplateInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateSelectListOptionArgs = {
  input: UpdateSelectListOptionInput;
};


export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};


export type MutationUpdateSupportTicketArgs = {
  input: UpdateSupportTicketInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export enum NetworkOrigin {
  Cellular = 'Cellular',
  Internet = 'Internet',
  LoraLiveObject = 'LoraLiveObject',
  LoraObjenious = 'LoraObjenious',
  SigfoxDataAdvanced = 'SigfoxDataAdvanced',
  Unknown = 'Unknown'
}

export type PaginatedAlertsOutput = {
  __typename?: 'PaginatedAlertsOutput';
  results: Array<AlertOutput>;
  total: Scalars['Int'];
};

export type PaginatedDeviceTemplatesOutput = {
  __typename?: 'PaginatedDeviceTemplatesOutput';
  results: Array<DeviceTemplateOutput>;
  total: Scalars['Int'];
};

export type PaginatedDevicesOutput = {
  __typename?: 'PaginatedDevicesOutput';
  results: Array<DeviceOutput>;
  total: Scalars['Int'];
};

export type PaginatedHolderTemplatesOutput = {
  __typename?: 'PaginatedHolderTemplatesOutput';
  results: Array<HolderTemplateOutput>;
  total: Scalars['Int'];
};

export type PaginatedHoldersOutput = {
  __typename?: 'PaginatedHoldersOutput';
  results: Array<HolderOutput>;
  total: Scalars['Int'];
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  alertTriggers: Array<AlertTriggerOutput>;
  alerts: PaginatedAlertsOutput;
  apiVersion: ApiVersionOutput;
  calibrationVersions: GetManyCalibrationVersionsOutput;
  calibrations: GetManyCalibrationsOutput;
  device: DeviceOutput;
  deviceEvents: GetDeviceEventOutput;
  deviceGroups?: Maybe<Scalars['JSON']>;
  deviceTemplates: PaginatedDeviceTemplatesOutput;
  devices: PaginatedDevicesOutput;
  holder: HolderOutput;
  holderGroups?: Maybe<Scalars['JSON']>;
  holderTemplateItems: Array<HolderTemplateItemOutput>;
  holderTemplates: PaginatedHolderTemplatesOutput;
  holders: PaginatedHoldersOutput;
  profile: UserOutput;
  settings: SettingOutput;
  supportMessages: Array<SupportMessageOutput>;
  supportTicket: SupportTicketOutput;
  supportTickets: GetSupportTicketOutput;
  unpaginatedHolders: Array<LightHolderOutput>;
  user: UserOutput;
  users: FindManyUsersOutput;
};


export type QueryAlertTriggersArgs = {
  holderId: Scalars['String'];
};


export type QueryAlertsArgs = {
  input?: InputMaybe<GetManyAlertsInput>;
};


export type QueryCalibrationVersionsArgs = {
  input?: InputMaybe<GetManyCalibrationVersionsInput>;
};


export type QueryCalibrationsArgs = {
  input?: InputMaybe<GetManyCalibrationsInput>;
};


export type QueryDeviceArgs = {
  input: GetUniqueDeviceInput;
};


export type QueryDeviceEventsArgs = {
  input?: InputMaybe<GetDeviceEventInput>;
};


export type QueryDevicesArgs = {
  input?: InputMaybe<GetManyDevicesInput>;
};


export type QueryHolderArgs = {
  input: GetUniqueHolderInput;
};


export type QueryHolderTemplateItemsArgs = {
  input: GetHolderTemplateItemsInput;
};


export type QueryHoldersArgs = {
  input?: InputMaybe<GetManyHoldersInput>;
};


export type QuerySupportMessagesArgs = {
  input: GetManySupportMessagesInput;
};


export type QuerySupportTicketArgs = {
  input: GetSupportTicketInput;
};


export type QuerySupportTicketsArgs = {
  input?: InputMaybe<GetManySupportTicketsInput>;
};


export type QueryUnpaginatedHoldersArgs = {
  input?: InputMaybe<GetManyHoldersInput>;
};


export type QueryUserArgs = {
  input: GetUserInput;
};


export type QueryUsersArgs = {
  input?: InputMaybe<FindManyUsersInput>;
};

export type RefreshAuthTokensInput = {
  refreshToken: Scalars['String'];
};

export type RenameDeviceGroupInput = {
  currentDirectoryPath: Scalars['String'];
  newName: Scalars['String'];
};

export type RenameHolderGroupInput = {
  currentDirectoryPath: Scalars['String'];
  newName: Scalars['String'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type SearchCalibrationsInput = {
  searchIn: Array<CalibrationSearchableField>;
  searchText: Scalars['String'];
};

export type SearchDeviceEventsInput = {
  searchIn: Array<DeviceEventSearchableField>;
  searchText: Scalars['String'];
};

export type SearchDevicesInput = {
  searchIn: Array<DeviceSearchableField>;
  searchText: Scalars['String'];
};

export type SearchHoldersInput = {
  searchIn: Array<HolderSearchableField>;
  searchText: Scalars['String'];
};

export type SearchSupportTicketsInput = {
  searchIn: Array<SupportTicketSearchableField>;
  searchText: Scalars['String'];
};

export type SearchUsersInput = {
  searchIn: Array<UserSearchableField>;
  searchText: Scalars['String'];
};

export type SelectListOptionOutput = {
  __typename?: 'SelectListOptionOutput';
  createdAt: Scalars['DateTime'];
  holderTemplateItemId: Scalars['String'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  requiredSelectListOptionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type SelectListOptionUpdateDataInput = {
  holderTemplateItemId?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  requiredSelectListOptionId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SendSupportMessageInput = {
  content: Scalars['String'];
  supportTicketId: Scalars['String'];
};

export type SettingOutput = {
  __typename?: 'SettingOutput';
  company: CompanySettingOutput;
  device: DeviceSettingOutput;
  emailing: EmailingSettingOutput;
  homePage: HomePageSettingOutput;
  signup: SignupSettingOutput;
  support: SupportSettingOutput;
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpAnotherUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRole;
};

export type SignUpInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type SignupSettingOutput = {
  __typename?: 'SignupSettingOutput';
  enabled: Scalars['Boolean'];
  passwordComplexityExplanation: Scalars['String'];
  passwordComplexityRegex: Scalars['String'];
  termsAndConditionsUrl: Scalars['String'];
};

export type SortAlertsInput = {
  field: AlertSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SortCalibrationsInput = {
  field: CalibrationSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SortDeviceEventsInput = {
  field: DeviceEventSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SortDevicesInput = {
  field: DeviceSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SortHoldersInput = {
  field: HolderSortableField;
  order?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortSupportTicketsInput = {
  field: SupportTicketSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SortUsersInput = {
  field: UserSortableField;
  order?: InputMaybe<SortOrder>;
};

export type SupportMessageOutput = {
  __typename?: 'SupportMessageOutput';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  creator: UserOutput;
  id: Scalars['String'];
  type: SupportMessageType;
};

export enum SupportMessageType {
  CustomerTechExchange = 'CUSTOMER_TECH_EXCHANGE',
  InternalNote = 'INTERNAL_NOTE'
}

export type SupportSettingOutput = {
  __typename?: 'SupportSettingOutput';
  allowUserToChatWithAgentByDefault: Scalars['Boolean'];
  allowUserToCreateTicketByDefault: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
};

export type SupportTicketOutput = {
  __typename?: 'SupportTicketOutput';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  creator: UserOutput;
  customer: UserOutput;
  customerId: Scalars['String'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  device: DeviceOutput;
  deviceId: Scalars['String'];
  id: Scalars['String'];
  internalComment?: Maybe<Scalars['String']>;
  messages: Array<SupportMessageOutput>;
  priority: SupportTicketPriority;
  status: SupportTicketStatus;
  tech?: Maybe<UserOutput>;
  techId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['String'];
};

export enum SupportTicketPriority {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum SupportTicketSearchableField {
  Code = 'code',
  Description = 'description',
  Id = 'id'
}

export enum SupportTicketSortableField {
  Code = 'code',
  CreatedAt = 'createdAt',
  Date = 'date',
  UpdatedAt = 'updatedAt'
}

export enum SupportTicketStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  ResolutionInProgress = 'RESOLUTION_IN_PROGRESS',
  TechnicianOnTheWay = 'TECHNICIAN_ON_THE_WAY',
  WaitingForClientResponse = 'WAITING_FOR_CLIENT_RESPONSE'
}

export type UpdateBatteryAlertTriggerInput = {
  id: Scalars['String'];
  minThreshold: Scalars['Float'];
};

export type UpdateCalibrationInput = {
  data: CalibrationUpdateDataInput;
  where: GetUniqueCalibrationInput;
};

export type UpdateCompanySettingInput = {
  faviconUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
};

export type UpdateDataAlertTriggerInput = {
  id: Scalars['String'];
};

export type UpdateDeviceInput = {
  data: DeviceUpdateDataInput;
  where: GetUniqueDeviceInput;
};

export type UpdateDeviceSettingInput = {
  registration?: InputMaybe<UpdateRegistrationSettingInput>;
};

export type UpdateDeviceTemplateDataInput = {
  decoderId: Scalars['String'];
  name: Scalars['String'];
  networkOrigin: NetworkOrigin;
};

export type UpdateDeviceTemplateInput = {
  data: UpdateDeviceTemplateDataInput;
  where: UpdateDeviceTemplateWhereInput;
};

export type UpdateDeviceTemplateWhereInput = {
  id: Scalars['String'];
};

export type UpdateEmailingSettingInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  templates?: InputMaybe<UpdateEmailingTemplateSettingInput>;
};

export type UpdateEmailingTemplateSettingInput = {
  accountCreation?: InputMaybe<Scalars['String']>;
  resetPassword?: InputMaybe<Scalars['String']>;
  signup?: InputMaybe<Scalars['String']>;
};

export type UpdateHolderInput = {
  data: HolderUpdateDataInput;
  where: GetUniqueHolderInput;
};

export type UpdateHolderTemplateDataInput = {
  items: Array<UpdateHolderTemplateItemInput>;
  name: Scalars['String'];
};

export type UpdateHolderTemplateInput = {
  data: UpdateHolderTemplateDataInput;
  where: GetUniqueHolderTemplateInput;
};

export type UpdateHolderTemplateItemInput = {
  checked?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  maxValOrLength?: InputMaybe<Scalars['Float']>;
  minValOrLength?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  rank: Scalars['Int'];
  required: Scalars['Boolean'];
  selectListOptions?: InputMaybe<Array<UpdateHolderTemplateItemSelectListOptionInput>>;
  type: HolderTemplateItemType;
};

export type UpdateHolderTemplateItemSelectListOptionInput = {
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateHomePageSettingInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  welcomeText?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateRegistrationSettingInput = {
  enabledToUsers?: InputMaybe<Scalars['Boolean']>;
  explanationImageUrl?: InputMaybe<Scalars['String']>;
  explanationText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateSelectListOptionInput = {
  data: SelectListOptionUpdateDataInput;
  where: GetUniqueSelectListOptionInput;
};

export type UpdateSettingsInput = {
  company?: InputMaybe<UpdateCompanySettingInput>;
  device?: InputMaybe<UpdateDeviceSettingInput>;
  emailing?: InputMaybe<UpdateEmailingSettingInput>;
  homePage?: InputMaybe<UpdateHomePageSettingInput>;
  signup?: InputMaybe<UpdateSignupSettingInput>;
  support?: InputMaybe<UpdateSupportSettingInput>;
};

export type UpdateSignupSettingInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  termsAndConditionsUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateSupportSettingInput = {
  allowUserToChatWithAgentByDefault?: InputMaybe<Scalars['Boolean']>;
  allowUserToCreateTicketByDefault?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSupportTicketDataInput = {
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<SupportTicketPriority>;
  status?: InputMaybe<SupportTicketStatus>;
  techId?: InputMaybe<Scalars['String']>;
};

export type UpdateSupportTicketInput = {
  data: UpdateSupportTicketDataInput;
  where: GetSupportTicketInput;
};

export type UpdateUserDataInput = {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  data: UpdateUserDataInput;
  where: GetUserInput;
};

export type UserHolderOutput = {
  __typename?: 'UserHolderOutput';
  holder: HolderOutput;
  holderId: Scalars['String'];
  user: UserOutput;
  userId: Scalars['String'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  connectedAt?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: UserRole;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Reseller = 'RESELLER',
  Tech = 'TECH'
}

export enum UserSearchableField {
  Address = 'address',
  City = 'city',
  Country = 'country',
  CustomFields = 'customFields',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  Zipcode = 'zipcode'
}

export enum UserSortableField {
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  UpdatedAt = 'updatedAt',
  Zipcode = 'zipcode'
}

export type AlertsQueryVariables = Exact<{
  input?: InputMaybe<GetManyAlertsInput>;
}>;


export type AlertsQuery = { __typename?: 'Query', alerts: { __typename?: 'PaginatedAlertsOutput', total: number, results: Array<{ __typename?: 'AlertOutput', id: string, createdAt: any, readAt?: any | null, type: AlertType, holder: { __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } } }> } };

export type MarkAlertsAsReadMutationVariables = Exact<{
  input: MarkAlertAsReadInput;
}>;


export type MarkAlertsAsReadMutation = { __typename?: 'Mutation', markAlertsAsRead: Array<{ __typename?: 'AlertOutput', id: string, createdAt: any, readAt?: any | null, type: AlertType, holder: { __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } } }> };

export type AlertSearchResultFragment = { __typename?: 'PaginatedAlertsOutput', total: number, results: Array<{ __typename?: 'AlertOutput', id: string, createdAt: any, readAt?: any | null, type: AlertType, holder: { __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } } }> };

export type AlertFragment = { __typename?: 'AlertOutput', id: string, createdAt: any, readAt?: any | null, type: AlertType, holder: { __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } } };

export type HolderFragment = { __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } };

export type HoldersQueryVariables = Exact<{
  input?: InputMaybe<GetManyHoldersInput>;
}>;


export type HoldersQuery = { __typename?: 'Query', holders: { __typename?: 'PaginatedHoldersOutput', total: number, results: Array<{ __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } }> } };

export type DeviceQueryVariables = Exact<{
  input: GetUniqueDeviceInput;
}>;


export type DeviceQuery = { __typename?: 'Query', device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } };

export type DeviceItemFragment = { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null };

export type HolderSearchResultFragment = { __typename?: 'PaginatedHoldersOutput', total: number, results: Array<{ __typename?: 'HolderOutput', id: string, name: string, customFields?: any | null, device: { __typename?: 'DeviceOutput', id: string, externalId: string, customId?: string | null, createdAt: any, updatedAt: any, lastMessageAt?: any | null, battery?: number | null, geolocation?: { __typename?: 'GeolocationOutput', lat: number, lng: number } | null } }> };

export type AssignDeviceToHolderMutationVariables = Exact<{
  input: AssignDeviceToHolderInput;
}>;


export type AssignDeviceToHolderMutation = { __typename?: 'Mutation', assignDeviceToHolder: { __typename?: 'DeviceOutput', id: string } };

export type DeviceIdFragment = { __typename?: 'DeviceOutput', id: string };

export type ClaimDeviceMutationVariables = Exact<{
  input: ClaimDeviceInput;
}>;


export type ClaimDeviceMutation = { __typename?: 'Mutation', claimDevice: { __typename?: 'HolderOutput', id: string } };

export type HolderTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type HolderTemplatesQuery = { __typename?: 'Query', holderTemplates: { __typename?: 'PaginatedHolderTemplatesOutput', total: number, results: Array<{ __typename?: 'HolderTemplateOutput', id: string, name: string, items: Array<{ __typename?: 'HolderTemplateItemOutput', id: string, name: string, type: HolderTemplateItemType, required: boolean, requiredSelectListOptionId?: string | null, minValOrLength?: number | null, maxValOrLength?: number | null, parentItemId?: string | null, selectListOptions: Array<{ __typename?: 'SelectListOptionOutput', id: string, name: string, isDefault: boolean, value: string, requiredSelectListOptionId?: string | null }> }> }> } };

export type HolderTemplatesSearchResultFragment = { __typename?: 'PaginatedHolderTemplatesOutput', total: number, results: Array<{ __typename?: 'HolderTemplateOutput', id: string, name: string, items: Array<{ __typename?: 'HolderTemplateItemOutput', id: string, name: string, type: HolderTemplateItemType, required: boolean, requiredSelectListOptionId?: string | null, minValOrLength?: number | null, maxValOrLength?: number | null, parentItemId?: string | null, selectListOptions: Array<{ __typename?: 'SelectListOptionOutput', id: string, name: string, isDefault: boolean, value: string, requiredSelectListOptionId?: string | null }> }> }> };

export type HolderTemplateOutputFragment = { __typename?: 'HolderTemplateOutput', id: string, name: string, items: Array<{ __typename?: 'HolderTemplateItemOutput', id: string, name: string, type: HolderTemplateItemType, required: boolean, requiredSelectListOptionId?: string | null, minValOrLength?: number | null, maxValOrLength?: number | null, parentItemId?: string | null, selectListOptions: Array<{ __typename?: 'SelectListOptionOutput', id: string, name: string, isDefault: boolean, value: string, requiredSelectListOptionId?: string | null }> }> };

export type HolderTemplateItemOutputFragment = { __typename?: 'HolderTemplateItemOutput', id: string, name: string, type: HolderTemplateItemType, required: boolean, requiredSelectListOptionId?: string | null, minValOrLength?: number | null, maxValOrLength?: number | null, parentItemId?: string | null, selectListOptions: Array<{ __typename?: 'SelectListOptionOutput', id: string, name: string, isDefault: boolean, value: string, requiredSelectListOptionId?: string | null }> };

export type SelectListOptionOutputFragment = { __typename?: 'SelectListOptionOutput', id: string, name: string, isDefault: boolean, value: string, requiredSelectListOptionId?: string | null };

export type CreateHolderMutationVariables = Exact<{
  input: CreateHolderInput;
}>;


export type CreateHolderMutation = { __typename?: 'Mutation', createHolder: { __typename?: 'HolderOutput', id: string } };

export type HolderIdFragment = { __typename?: 'HolderOutput', id: string };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'AuthOutput', user: { __typename?: 'UserOutput', id: string, email: string, firstName?: string | null, lastName?: string | null, role: UserRole, createdAt: any, updatedAt: any, address?: string | null, city?: string | null, zipcode?: string | null, country?: string | null, phoneNumber?: string | null }, tokens: { __typename?: 'AuthTokensOutput', accessToken: string, refreshToken: string } } };

export type LoginMutationVariables = Exact<{
  input: SignInInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', signIn: { __typename?: 'AuthOutput', user: { __typename?: 'UserOutput', id: string, email: string, firstName?: string | null, lastName?: string | null, role: UserRole, createdAt: any, updatedAt: any, address?: string | null, city?: string | null, zipcode?: string | null, country?: string | null, phoneNumber?: string | null }, tokens: { __typename?: 'AuthTokensOutput', accessToken: string, refreshToken: string } } };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'UserOutput', id: string, email: string, firstName?: string | null, lastName?: string | null, role: UserRole, createdAt: any, updatedAt: any, address?: string | null, city?: string | null, zipcode?: string | null, country?: string | null, phoneNumber?: string | null } };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: any | null };

export type ResetPasswordMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', requestPasswordReset?: any | null };

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = { __typename?: 'Mutation', signOut?: any | null };

export type RefreshTokensMutationVariables = Exact<{
  input: RefreshAuthTokensInput;
}>;


export type RefreshTokensMutation = { __typename?: 'Mutation', refreshAuthTokens: { __typename?: 'AuthTokensOutput', accessToken: string, refreshToken: string } };

export type AuthFragment = { __typename?: 'AuthOutput', user: { __typename?: 'UserOutput', id: string, email: string, firstName?: string | null, lastName?: string | null, role: UserRole, createdAt: any, updatedAt: any, address?: string | null, city?: string | null, zipcode?: string | null, country?: string | null, phoneNumber?: string | null }, tokens: { __typename?: 'AuthTokensOutput', accessToken: string, refreshToken: string } };

export type AuthTokensOutputFragment = { __typename?: 'AuthTokensOutput', accessToken: string, refreshToken: string };

export type UserOutputFragment = { __typename?: 'UserOutput', id: string, email: string, firstName?: string | null, lastName?: string | null, role: UserRole, createdAt: any, updatedAt: any, address?: string | null, city?: string | null, zipcode?: string | null, country?: string | null, phoneNumber?: string | null };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'UserOutput', id: string } };

export type UserIdFragment = { __typename?: 'UserOutput', id: string };

export const DeviceItemFragmentDoc = gql`
    fragment DeviceItem on DeviceOutput {
  id
  externalId
  customId
  geolocation {
    lat
    lng
  }
  createdAt
  updatedAt
  lastMessageAt
  battery
}
    `;
export const HolderFragmentDoc = gql`
    fragment Holder on HolderOutput {
  id
  name
  customFields
  device {
    ...DeviceItem
  }
}
    ${DeviceItemFragmentDoc}`;
export const AlertFragmentDoc = gql`
    fragment Alert on AlertOutput {
  id
  createdAt
  holder {
    ...Holder
  }
  readAt
  type
}
    ${HolderFragmentDoc}`;
export const AlertSearchResultFragmentDoc = gql`
    fragment AlertSearchResult on PaginatedAlertsOutput {
  results {
    ...Alert
  }
  total
}
    ${AlertFragmentDoc}`;
export const HolderSearchResultFragmentDoc = gql`
    fragment HolderSearchResult on PaginatedHoldersOutput {
  results {
    ...Holder
  }
  total
}
    ${HolderFragmentDoc}`;
export const DeviceIdFragmentDoc = gql`
    fragment DeviceId on DeviceOutput {
  id
}
    `;
export const SelectListOptionOutputFragmentDoc = gql`
    fragment SelectListOptionOutput on SelectListOptionOutput {
  id
  name
  isDefault
  value
  requiredSelectListOptionId
}
    `;
export const HolderTemplateItemOutputFragmentDoc = gql`
    fragment HolderTemplateItemOutput on HolderTemplateItemOutput {
  id
  name
  type
  required
  requiredSelectListOptionId
  minValOrLength
  maxValOrLength
  selectListOptions {
    ...SelectListOptionOutput
  }
  parentItemId
}
    ${SelectListOptionOutputFragmentDoc}`;
export const HolderTemplateOutputFragmentDoc = gql`
    fragment HolderTemplateOutput on HolderTemplateOutput {
  id
  name
  items {
    ...HolderTemplateItemOutput
  }
}
    ${HolderTemplateItemOutputFragmentDoc}`;
export const HolderTemplatesSearchResultFragmentDoc = gql`
    fragment HolderTemplatesSearchResult on PaginatedHolderTemplatesOutput {
  results {
    ...HolderTemplateOutput
  }
  total
}
    ${HolderTemplateOutputFragmentDoc}`;
export const HolderIdFragmentDoc = gql`
    fragment HolderId on HolderOutput {
  id
}
    `;
export const UserOutputFragmentDoc = gql`
    fragment UserOutput on UserOutput {
  id
  email
  firstName
  lastName
  role
  createdAt
  updatedAt
  address
  city
  zipcode
  country
  phoneNumber
}
    `;
export const AuthTokensOutputFragmentDoc = gql`
    fragment AuthTokensOutput on AuthTokensOutput {
  accessToken
  refreshToken
}
    `;
export const AuthFragmentDoc = gql`
    fragment Auth on AuthOutput {
  user {
    ...UserOutput
  }
  tokens {
    ...AuthTokensOutput
  }
}
    ${UserOutputFragmentDoc}
${AuthTokensOutputFragmentDoc}`;
export const UserIdFragmentDoc = gql`
    fragment UserId on UserOutput {
  id
}
    `;
export const AlertsDocument = gql`
    query alerts($input: GetManyAlertsInput) {
  alerts(input: $input) {
    ...AlertSearchResult
  }
}
    ${AlertSearchResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AlertsGQL extends Apollo.Query<AlertsQuery, AlertsQueryVariables> {
    document = AlertsDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkAlertsAsReadDocument = gql`
    mutation markAlertsAsRead($input: MarkAlertAsReadInput!) {
  markAlertsAsRead(input: $input) {
    ...Alert
  }
}
    ${AlertFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkAlertsAsReadGQL extends Apollo.Mutation<MarkAlertsAsReadMutation, MarkAlertsAsReadMutationVariables> {
    document = MarkAlertsAsReadDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HoldersDocument = gql`
    query holders($input: GetManyHoldersInput) {
  holders(input: $input) {
    ...HolderSearchResult
  }
}
    ${HolderSearchResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HoldersGQL extends Apollo.Query<HoldersQuery, HoldersQueryVariables> {
    document = HoldersDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeviceDocument = gql`
    query device($input: GetUniqueDeviceInput!) {
  device(input: $input) {
    ...DeviceItem
  }
}
    ${DeviceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeviceGQL extends Apollo.Query<DeviceQuery, DeviceQueryVariables> {
    document = DeviceDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignDeviceToHolderDocument = gql`
    mutation assignDeviceToHolder($input: AssignDeviceToHolderInput!) {
  assignDeviceToHolder(input: $input) {
    ...DeviceId
  }
}
    ${DeviceIdFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignDeviceToHolderGQL extends Apollo.Mutation<AssignDeviceToHolderMutation, AssignDeviceToHolderMutationVariables> {
    document = AssignDeviceToHolderDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClaimDeviceDocument = gql`
    mutation claimDevice($input: ClaimDeviceInput!) {
  claimDevice(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClaimDeviceGQL extends Apollo.Mutation<ClaimDeviceMutation, ClaimDeviceMutationVariables> {
    document = ClaimDeviceDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HolderTemplatesDocument = gql`
    query holderTemplates {
  holderTemplates {
    ...HolderTemplatesSearchResult
  }
}
    ${HolderTemplatesSearchResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HolderTemplatesGQL extends Apollo.Query<HolderTemplatesQuery, HolderTemplatesQueryVariables> {
    document = HolderTemplatesDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHolderDocument = gql`
    mutation createHolder($input: CreateHolderInput!) {
  createHolder(input: $input) {
    ...HolderId
  }
}
    ${HolderIdFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHolderGQL extends Apollo.Mutation<CreateHolderMutation, CreateHolderMutationVariables> {
    document = CreateHolderDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignUpDocument = gql`
    mutation signUp($input: SignUpInput!) {
  signUp(input: $input) {
    ...Auth
  }
}
    ${AuthFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SignUpGQL extends Apollo.Mutation<SignUpMutation, SignUpMutationVariables> {
    document = SignUpDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($input: SignInInput!) {
  signIn(input: $input) {
    ...Auth
  }
}
    ${AuthFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileDocument = gql`
    query profile {
  profile {
    ...UserOutput
  }
}
    ${UserOutputFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileGQL extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
    document = ProfileDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAccountGQL extends Apollo.Mutation<DeleteAccountMutation, DeleteAccountMutationVariables> {
    document = DeleteAccountDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignOutDocument = gql`
    mutation signOut {
  signOut
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignOutGQL extends Apollo.Mutation<SignOutMutation, SignOutMutationVariables> {
    document = SignOutDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefreshTokensDocument = gql`
    mutation refreshTokens($input: RefreshAuthTokensInput!) {
  refreshAuthTokens(input: $input) {
    ...AuthTokensOutput
  }
}
    ${AuthTokensOutputFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshTokensGQL extends Apollo.Mutation<RefreshTokensMutation, RefreshTokensMutationVariables> {
    document = RefreshTokensDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    ...UserId
  }
}
    ${UserIdFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProfileGQL extends Apollo.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> {
    document = UpdateProfileDocument;
    client = 'core';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }